<template>
  <div id="app">
    <Form
      v-if="view_form"
      :token="token"
      :project="project_name"
      :button_color="button_color"
      :button_text="button_text"
      :background_color="background_color"
      :input_color="input_color"
      :source="source"
      :contact="contact"
      :customer="customer"
    />
    <Scrapper
      v-else
      :token="token"
      :name="name"
      :lastname="lastname"
      :rut="rut"
      :email="email"
      :phone="phone"
      :submit="submit"
      :project_name="project_name"
      :project_id="project_id"
      :source="source"
      :contact="contact"
      :no_dni="no_dni"
      :dni="dni"
      :observation="observation"
      :customer="customer"
      :income_range="income_range"
      :utm_campaign="utm_campaign"
      :utm_medium="utm_medium"
      :utm_source="utm_source"
      :utm_term="utm_term"
      :utm_content="utm_content"
    />
  </div>
</template>

<script>
import Scrapper from "./components/Scrapper.vue";
import Form from "./components/Form.vue";

export default {
  name: "App",
  components: {
    Scrapper,
    Form
  },
  props: {
    name: { default: null },
    lastname: { default: null },
    rut: { default: null },
    email: { default: null },
    phone: { default: null },
    submit: { default: null },
    project_name: { default: null },
    project_id: { default: null },
    token: { default: null },
    customer: { default: null },
    view_form: { default: null },
    button_color: { default: "#337ab7" },
    button_text: { default: "#ffffff" },
    background_color: { default: "transparent" },
    input_color: { default: "#222222" },
    observation: { default: null },
    source: { default: null },
    contact: { default: null },
    dni: { default: false },
    no_dni: { default: false },
    income_range: { default: null },
    utm_campaign: { default: null },
    utm_medium: { default: null },
    utm_source: { default: null },
    utm_term: { default: null },
    utm_content: { default: null }
  }
};
</script>
